<template >
  <div>
    <v-row>
      <v-col>
        <template>
          <v-alert
            v-show="getIsSuccess"
            dismissible
            dense
            text
            type="success"
            @input="closeAlert"
          >
            El pago se ha realizado de forma correcta
          </v-alert>
          <v-expansion-panels v-model="value">
            <v-expansion-panel>
              <!-- <v-expansion-panel-header> </v-expansion-panel-header> -->
              <v-expansion-panel-content style="position: relative">
                <v-row v-if="isLoading" class="py-4">
                  <Loading />
                </v-row>
                <v-row v-else-if="getMembership" class="my-4">
                  <v-col cols="12">
                    <h2 class="">Orden # {{ getMembership.order.id }}</h2>
                  </v-col>
                  <v-col cols="12">
                    <!-- <h3>Usted ha adquirido el servicio</h3> -->
                    <router-link
                      :to="{
                        name: 'Service',
                        params: {
                          slug: getMembership.order.details.product.slug,
                        },
                      }"
                      ><h2 class="mt-3 primary--text">
                        {{ getMembership.order.details.product.name }}
                      </h2></router-link
                    >
                  </v-col>
                  <v-col cols="12" md="6">
                    <p>
                      <span class="font-weight-bold">Fecha de inicio</span>:
                    </p>
                    <p class="subtitle-1">
                      {{ getMembership.start_date }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="6">
                    <p>
                      <span class="font-weight-bold">Fecha de culminnación</span
                      >:
                    </p>
                    <p class="subtitle-1">
                      {{ getMembership.end_date }}
                    </p>
                  </v-col>

                  <v-col cols="12" md="12" class="">
                    <p><span class="font-weight-bold">Total</span>:</p>
                    <h2 class="font-weight-bold primary--text">
                      {{ getMembership.order.total | formatCurrency }}
                      {{ getMembership.order.currency.name }}
                    </h2>
                  </v-col>
                  <v-col cols="12" md="12" class="" v-if="!getMembership.is_company">
                    <v-btn @click="deleteItemConfirm" color="red" dark depressed
                      >Cancelar suscripción</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row v-else class="py-4">
                  <v-card-text>
                    <div class="text-center">
                      <h2 class="text-h6 text-md-h5 font-weight-bold">
                        No has adquirido aún algunos de nuestros servicios.
                      </h2>
                      <img
                        width="100%"
                        class="mt-6"
                        src="../../assets/ilustracions/undraw_product_tour_re_8bai.svg"
                        height="200"
                      />
                    </div>
                  </v-card-text>
                </v-row>

                <!-- <v-btn
                  fab
                  @click="goBack"
                  color="primary"
                  depressed
                  style="position: absolute; right: 10px; bottom: 10px"
                >
                  <v-icon>mdi-keyboard-backspace</v-icon>
                </v-btn> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogDelete"
      max-width="600px"
      :persistent="dialogPersistent"
    >
      <v-card>
        <v-card-title class="headline"
          >Esta seguro que desea cancelar su servicio?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialogDelete = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary darken-1"
            :loading="isBtnLoading"
            depressed
            @click="cancelSuscription"
            >Confirmar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import { formatDate } from "../../filters/moment";
import { formatCurrency } from "../../filters/currency";
import Loading from "../../components/dashboard/Loading.vue";
export default {
  components: { Loading },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Mi Membresia",
      };
    },
  },
  name: "MyMembreship",
  async created() {

    if (this.getIsSuccess) {
      setTimeout(() => {
        this.setIsSuccess(false);
      }, 10000);
    }
    this.isLoading = true;
    await this.getMyMembership();
    this.isLoading = false;
    this.setErrors("clear");
  },
  filters: {
    formatDate,
    formatCurrency,
  },
  data() {
    return {
      value: 0,
      isLoading: false,
      dialogDelete: false,
      isBtnLoading: false,
      dialogPersistent: false,
    };
  },
  computed: {
    ...mapGetters("membership", ["getIsSuccess", "getMembership"]),
  },

  methods: {
    closeAlert() {
      this.setIsSuccess(false);
    },
    goBack() {
      this.$router.go(-1);
    },

    async deleteItemConfirm() {
      this.dialogDelete = true;
    },

    async cancelSuscription() {
      this.dialogPersistent = true;
      const customer_id = this.getMembership.user.UserBroker.conekta;
      const membership_id = this.getMembership.id;
      this.isBtnLoading = true;
      await this.calcelMyMembership({ customer_id, membership_id });
      this.isBtnLoading = false;
      localStorage.removeItem("view_membreship");

      await this.getMyMembership();
      this.dialogPersistent = false;
      this.dialogDelete = false;
    },
    ...mapActions("membership", [
      "setIsSuccess",
      "setErrors",
      "getMyMembership",
      "calcelMyMembership",
    ]),
  },
};
</script>

<style>
/* * {
  border: 1px solid red;
} */
</style>
